// -- The Link Resolver
// This function will be used to generate links to Prismic documents
// As your project grows, you should update this function according to your routes

const linkResolver = (doc) => {
  if (doc.type === 'post') {
    return `/blog/${doc.uid}`
  }

  if (doc.type === 'photogroup'){
    return `/photography/${doc.uid}`
  }
  if (doc.type === 'discipline-type'){
    return `/${doc.uid}`
  }
  if (doc.type === 'video-item'){
    return `/video/${doc.uid}`
  }
  if (doc.type === 'audio-item'){
    return `/audio/${doc.uid}`
  }
  if (doc.type === 'design-set'){
    return `/design/${doc.uid}`
  }
  if (doc.type === 'writing-item'){
    return `/writing/${doc.uid}`
  }
  if (doc.type === 'code-item'){
    return `/code/${doc.uid}`
  }
  return '/'
}

module.exports = linkResolver
